import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import Spinner from "@col-care/common/elements/spinner/Spinner";
import EmptyCart from "@col-care/common/blocks/empty-cart";

import { getReduxStore } from "../state/configureStore";
import { getBoundActions } from "../state/actions";
import { posthogEvents } from "@col-care/common/utilities/constants";

// import Button from "../components/button/Button";
import JaneEmbed from "@col-care/common/providers/iheartjane/jane-embed/JaneEmbed";
import LoadingSVG from "@col-care/common/elements/icons/LoadingSVG";
import { capturePosthogLink } from "@col-care/common/utilities/index";

import * as JaneStyles from "../styles/jane.module.css";

const Checkout = (props) => {
  const location = useSelector((state) => state.router.location);
  const user = useSelector((state) => state.auth.currentUser);
  const store = getReduxStore("init", location?.pathname).store;
  const actions = getBoundActions(store.dispatch);
  const cartData = useSelector((state) => state.session.cartData);
  const siteConfigData = useSelector((state) => state.hydrate.siteConfigData);
  const rehydrated = useSelector((state) => state._persist?.rehydrated);
  const posthog = props?.posthog;

  return (
    <JaneCheckout
      user={user}
      cartData={cartData}
      siteConfigData={siteConfigData}
      rehydrated={rehydrated}
      doSetCartData={actions.session.doSetCartData}
      authFeature={siteConfigData.features?.authentication?.status}
      doNavigate={(pathname) => {
        navigate(pathname);
        capturePosthogLink({
          eventName: posthogEvents.JANE_NAVIGATION,
          title: "Jane Checkout",
          to: pathname,
          posthog,
        });
      }}
      posthog={posthog}
    />
  );
};

export default Checkout;

const JaneCheckout = React.memo((props) => {
  const user = props.user;
  const cartData = props.cartData;
  const doNavigate = props.doNavigate;
  const rehydrated = props.rehydrated;
  const posthog = props?.posthog;

  const [mounted, setMounted] = useState(false);
  const [embedConfig, setEmbedConfig] = useState(null);
  const [janeCheckoutCompleted, setJaneCheckoutCompleted] = useState(null);
  const [janeCheckoutUpdatedCart, setJaneCheckoutUpdatedCart] = useState(null);
  const [cartItemAdded, setCartItemAdded] = useState(null);

  // @todo: if going directly to checkout this needs to wait for cognito token
  const janeEmbedConfig = {
    iframeSrc: `https://www.iheartjane.com/headless/cart`,
    storeId: props.cartData.store.janeId,
    themeColor: "#345543",
    navigationColor: "#8cbb66",
    ctaTextColor: "#ffffff",
    action: "buildCart",
    cartData: props.cartData,
  };

  if (props.authFeature !== "disabled") {
    janeEmbedConfig.userData = {
      firstName: user?.attributes?.given_name,
      lastName: user?.attributes?.family_name,
      birthDate: user?.attributes?.birthdate,
      phone: user?.attributes?.phone_number?.slice(2), // @todo: do more cleanly
      email: user?.attributes?.email,
      externalId: user?.attributes?.sub,
    };
    janeEmbedConfig.cognitoToken = user
      ? user?.signInUserSession?.accessToken?.jwtToken
      : null;
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setEmbedConfig(janeEmbedConfig);
  }, [props.cartData]);

  useEffect(() => {
    if (janeCheckoutCompleted) {
      if (typeof window === "object" && window.twq) {
        window.twq("event", "tw-ofxar-ofxaw", {
          value: janeCheckoutCompleted.properties.estimatedTotal,
          currency: "USD",
        });
      }
      localStorage.setItem(
        "tgs-jane-cart-" + janeCheckoutCompleted.properties.cartId,
        JSON.stringify(props.cartData)
      );

      if (posthog) {
        console.info(`Posthog event -> ${posthogEvents.JANE_CHECKOUT_SUCCESS}`);
        posthog?.capture(posthogEvents.JANE_CHECKOUT_SUCCESS, {
          $EventDetails: {
            orderDetails: janeCheckoutCompleted.properties,
            cartData: props.cartData,
          },
        });
      }

      const cartData = props.cartData;
      cartData.products = [];
      cartData.productsCount = 0;
      cartData.specials = [];
      props.doSetCartData(cartData);

      navigate(
        "/checkout/success?id=" + janeCheckoutCompleted.properties.cartId
      );
    }
  }, [janeCheckoutCompleted]);

  const getProductById = async (url, requestOptions) => {
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return data?.results[0]?.hits[0];
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (janeCheckoutUpdatedCart) {
        // console.log("janeCheckoutUpdatedCart", janeCheckoutUpdatedCart);
        // console.log("current cart", props.cartData);
        const path = `https://${props?.siteConfigData?.env?.jane?.application_id}-dsn.algolia.net/1/indexes/*/queries`;

        if (typeof window === "object") {
          const scrollTo = document.getElementById("app");
          window.scroll({ top: scrollTo, behavior: "smooth" });
        }

        if (janeCheckoutUpdatedCart.length < props.cartData.products.length) {
          if (posthog) {
            console.info(
              `Posthog event -> ${posthogEvents.JANE_CHECKOUT_ITEM_REMOVED}`
            );
            posthog?.capture(posthogEvents.JANE_CHECKOUT_ITEM_REMOVED, {
              $EventDetails: {
                previousCart: props.cartData.products,
                newCart: janeCheckoutUpdatedCart,
              },
            });
          }
        }
        if (janeCheckoutUpdatedCart.length > props.cartData.products.length) {
          console.info(
            `Posthog event -> ${posthogEvents.JANE_CHECKOUT_ITEM_ADDED}`
          );
          posthog?.capture(posthogEvents.JANE_CHECKOUT_ITEM_ADDED, {
            $EventDetails: {
              previousCart: props.cartData.products,
              newCart: janeCheckoutUpdatedCart,
            },
          });
        }

        const cartData = { ...props.cartData }; // Clone cartData to avoid mutations

        const foundMap = {};

        for (const val of janeCheckoutUpdatedCart) {
          foundMap[val.productId] = true;
          const cartIndex = cartData.products.findIndex(
            (item) => item.productId === val.productId
          );

          if (~cartIndex) {
            cartData.products[cartIndex].count = val.count;
          } else if (
            cartItemAdded &&
            cartItemAdded.productId === val.productId
          ) {
            setCartItemAdded(null);
            const raw = JSON.stringify({
              requests: [
                {
                  indexName: "menu-products-production",
                  params: `filters=product_id:${val.productId}`,
                },
              ],
            });

            const requestOptions = {
              method: "POST",
              body: raw,
              headers: {
                "X-Algolia-Application-Id":
                  props?.siteConfigData?.env?.jane?.application_id,
                "X-Algolia-API-Key":
                  props?.siteConfigData?.env?.jane?.algolia_key,
              },
            };

            try {
              const productData = await getProductById(path, requestOptions);
              cartData.products.push({
                productId: val.productId,
                priceId: val.priceId,
                count: val.count,
                name: productData?.name || "",
                brand: productData?.brand || "",
                photo: productData?.image_urls[0] || "",
                price: parseFloat(productData[`price_${val.priceId}`]),
              });
            } catch (error) {
              console.warn(error);
              // Handle or log the error
            }
          }
        }

        cartData.products = cartData.products.filter(
          (val) => foundMap[val.productId]
        );

        props.doSetCartData(cartData);
      }
    };

    fetchData();
  }, [janeCheckoutUpdatedCart]);

  return rehydrated && mounted && embedConfig ? (
    <>
      {!!cartData?.products?.length ? (
        <JaneEmbed
          cartData={cartData}
          janeEmbedConfig={embedConfig}
          janeCheckoutCompleted={janeCheckoutCompleted}
          setJaneCheckoutCompleted={setJaneCheckoutCompleted}
          setCartItemAdded={setCartItemAdded}
          janeCheckoutUpdatedCart={janeCheckoutUpdatedCart}
          setJaneCheckoutUpdatedCart={setJaneCheckoutUpdatedCart}
          doNavigate={doNavigate}
          loader={LoadingSVG}
          posthog={posthog}
        />
      ) : (
        <EmptyCart currentStoreId={cartData?.store?.id} posthog={posthog} />
      )}
    </>
  ) : (
    <div style={{ minHeight: "300px", position: "relative" }}>
      <Spinner />
    </div>
  );
});
