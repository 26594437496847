import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Spinner from "@col-care/common/elements/spinner/Spinner";

import { nanoid } from "nanoid";

import * as janeConstants from "./constants";

// import { useJaneContext } from "./context";
import {
  buildJaneOptions,
  handleWindowMessages,
  handleResize,
  setDistanceToTop,
} from "./index";

const JaneEmbed = (props) => {
  // const context = useJaneContext();

  // jane wants a document reference
  const thisDoc =
    typeof document === "object"
      ? document.getElementsByTagName("body")[0]
      : null;
  const janeBodyRef = useRef(thisDoc);

  // DOM refs
  const janeEmbedMenuDivRef = useRef(null);
  const janeEmbedIframeDivRef = useRef(null);
  const janeEmbedRef = useRef(null);

  // not-DOM refs
  // const janeEmbedLoadedRef = useRef(false);
  const janeResizedMessageTimerRef = useRef();
  const janeRenderKeyRef = useRef(nanoid());
  const janeIntersectionObserverRef = useRef();
  const janeResizeObserverRef = useRef();

  // states
  const [janeDeviceId, setJaneDeviceId] = useState(null);
  const [janeDistanceToTopOfWindow, setJaneDistanceToTopOfWindow] = useState(0);
  const [janeEmbedLoaded, setJaneEmbedLoaded] = useState(0);
  // var preventAutoScrolling = query[0] && query[0].includes('noScroll=1');
  const [janePreventAutoScrolling, setJanePreventAutoScrolling] =
    useState(false);
  const [janePreventResizing, setJanePreventResizing] = useState(false);
  const [janePreventContainerResizing, setJanePreventContainerResizing] =
    useState(false);
  const [janeOriginalScrollPosition, setJaneOriginalScrollPosition] =
    useState(0);

  const janeCheckoutCompleted = props.janeCheckoutCompleted;
  const setJaneCheckoutCompleted = props.setJaneCheckoutCompleted;
  const janeCheckoutUpdatedCart = props.janeCheckoutUpdatedCart;
  const setJaneCheckoutUpdatedCart = props.setJaneCheckoutUpdatedCart;
  const setCartItemAdded = props.setCartItemAdded;
  const cartData = props.cartData;
  const doNavigate = props.doNavigate;

  // console.log("janeCheckoutUpdatedCart ===>", janeCheckoutUpdatedCart);

  const context = {
    janeBodyRef,
    janeEmbedMenuDivRef,
    janeEmbedIframeDivRef,
    janeEmbedRef,
    janeResizedMessageTimerRef,
    janeRenderKeyRef,
    janeIntersectionObserverRef,
    janeResizeObserverRef,
    janeEmbedLoaded,
    cartData,
    doNavigate,
    setJaneEmbedLoaded,
    janeDeviceId,
    setJaneDeviceId,
    janePreventAutoScrolling,
    setJanePreventAutoScrolling,
    janeDistanceToTopOfWindow,
    setJaneDistanceToTopOfWindow,
    janePreventResizing,
    setJanePreventResizing,
    janePreventContainerResizing,
    setJanePreventContainerResizing,
    janeOriginalScrollPosition,
    setJaneOriginalScrollPosition,
    janeCheckoutCompleted,
    setJaneCheckoutCompleted,
    janeCheckoutUpdatedCart,
    setJaneCheckoutUpdatedCart,
    setCartItemAdded,
  };

  const legacyCallback = (event) => {
    var payload = event.data && event.data.payload;
    var messageType = event.data && event.data.messageType;

    if (
      messageType === "loadingEvent" &&
      payload.name === "embeddedAppLoaded"
    ) {
      setJaneEmbedLoaded(true);
    }
  };

  useEffect(() => {
    const resizeCb = () => handleResize(context);
    const messageCb = () => handleWindowMessages(event, context);

    context.janeIntersectionObserverRef.current = new IntersectionObserver(
      function () {}
    );
    context.janeResizeObserverRef.current = new ResizeObserver(resizeCb);

    window.addEventListener("resize", resizeCb);

    if (props.legacy) {
      window.addEventListener("message", legacyCallback, false);
    }

    const script = document.createElement("script");
    script.id = "jane-embed";
    script.src = props.legacy
      ? props.janeEmbedConfig.iframeSrc
      : janeConstants.EMBEDDED_SRC;
    script.async = true;
    document.getElementById("jane").appendChild(script);

    window.addEventListener("message", messageCb, false);

    return () => {
      context.janeIntersectionObserverRef.current = null;
      context.janeResizeObserverRef.current = null;
      window.removeEventListener("resize", resizeCb);
      if (props.legacy) {
        window.removeEventListener("message", legacyCallback, false);
        window.removeEventListener("message", messageCb, false);
      } else {
        window.removeEventListener("message", messageCb, false);
      }
    };
  }, []);

  useEffect(() => {
    const menuDiv = document.getElementById("menu-div");
    context.janeEmbedMenuDivRef.current = menuDiv;

    const iframeDiv = document.getElementById("iframe-div");
    context.janeEmbedIframeDivRef.current = iframeDiv;

    const frame = document.getElementById(janeConstants.JANE_EMBED_ID);
    context.janeEmbedRef.current = frame;

    if (frame && frame.contentWindow) {
      context.janeResizeObserverRef.current.observe(frame);
      context.janeResizeObserverRef.current.observe(
        context.janeBodyRef.current
      );

      frame.setAttribute("scrolling", "no");

      // try {
      //   const deviceId =  window.localStorage.getItem('j_device_id') || window.localStorage.setItem('j_device_id', nanoid())
      //   context.setJaneDeviceId({ args: { deviceId: deviceId } })
      // }
      // catch (err) {
      //   console.log('localStorage error', err);
      // }

      setDistanceToTop(
        context.janeEmbedMenuDivRef,
        context.janeDistanceToTopOfWindow
      );
      // send Jane init payload
      const opts = buildJaneOptions(props.janeEmbedConfig.action, {
        cartData: props.janeEmbedConfig.cartData || null,
        storeId: props.janeEmbedConfig.storeId || null,
        themeColor: props.janeEmbedConfig.themeColor,
        navigationColor: props.janeEmbedConfig.navigationColor,
        ctaTextColor: props.janeEmbedConfig.ctaTextColor,
        buttonBorderRadius:
          props.janeEmbedConfig.buttonBorderRadius || "0.5rem",
        cognitoToken: props.janeEmbedConfig.cognitoToken,
        userData: props.janeEmbedConfig.userData,
      });

      frame.contentWindow.postMessage(opts, "*");
    }
  }, [context.janeEmbedLoaded]);

  return (
    <div
      id="jane"
      style={{ minHeight: "350px", background: "#fff", position: "relative" }}
    >
      <div id="jane-embed">{!janeEmbedLoaded && <Spinner />}</div>
      <div id="jane-frame-script"></div>
    </div>
  );
};

JaneEmbed.propTypes = {
  janeEmbedConfig: PropTypes.object.isRequired,
  legacy: PropTypes.bool,
  janeCheckoutCompleted: PropTypes.bool,
  setJaneCheckoutCompleted: PropTypes.func,
  janeCheckoutUpdatedCart: PropTypes.arrayOf(PropTypes.any),
  setJaneCheckoutUpdatedCart: PropTypes.func,
  setCartItemAdded: PropTypes.func,
  cartData: PropTypes.object,
  doNavigate: PropTypes.func,
};

JaneEmbed.displayName = "JaneEmbed";

export default JaneEmbed;
