import React from "react";
import { navigate } from "gatsby";
import Button from "../../elements/button/Button";
import { posthogEvents } from "@col-care/common/utilities/constants";
import { capturePosthogLink } from "@col-care/common/utilities/index";
import * as stylesObj from "./index.module.css";

const EmptyCart = ({ currentStoreId, posthog }) => {
  const styles = stylesObj.default || stylesObj;

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Your cart is empty!</h1>
      <p>View the menu to add items to your cart!</p>
      {!!currentStoreId && (
        <Button
          theme="checkOutButtonTGS"
          label={`Shop Now`}
          onClick={(e) => {
            navigate("/shop/" + currentStoreId);
            capturePosthogLink({
              eventName: posthogEvents.STORE_NAVIGATION,
              title: "SHOP NOW",
              to: "/shop/" + currentStoreId,
              posthog,
            });
          }}
        />
      )}
    </div>
  );
};

export default EmptyCart;
